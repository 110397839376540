const theme = {
  colors: {
    lightBlue: '#202e54',
    blue: '#1E2841',
    darkBlue: '#141B2C',
    lightOrangeBackground: '#e65725',
    orangeBackground: '#E04715',
    mainText: '#FFF',
    descriptionText: '#C2C2CE',
  },
};

export default theme;
